import React from 'react';
import {css} from '@emotion/core';

import Unicyclist from '../assets/unicyclist.svg';
import Balancer from '../assets/balancer.svg';

const HomeAnimation = () => (
  <section css={css`
    width: 50%;
    margin-top: 1rem;
    text-align: center;

    @media only screen and (max-width : 680px) {
      order: 1;
      width: 100%;
    }

    .spotlight {
      position: absolute;
      animation: xAxis 3.5s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
    }
    
    .spotlight::after {
      content: '';
      display: block;
      width: 350px;
      height: 350px;
      border-radius: 350px;
      background-color: rgba(69,155,199,.33);
      animation: yAxis 2.5s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);

      @media only screen and (max-width : 740px) {
        width: 250px;
        height: 250px;
        border-radius: 250px;
      }
    }
    
    @keyframes yAxis {
      50% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(-20px);
      }
    }
    
    @keyframes xAxis {
      50% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(120px);
      }
    }

    #spotlight2 {
      animation: xAxis2 5s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
    }
    
    #spotlight2::after {
      animation: yAxis2 5s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    }
    
    @keyframes yAxis2 {
      50% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(-30px);
      }
    }
    
    @keyframes xAxis2 {
      50% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
        transform: translateX(110px);
      }
    }

    #spotlight3 {
      animation: xAxis3 7s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
    }
    
    #spotlight3::after {
      animation: yAxis3 7s infinite cubic-bezier(0.3, 0.27, 0.07, 1.64);
    }
    
    @keyframes yAxis3 {
      50% {
        animation-timing-function: cubic-bezier(0.02, 0.01, 0.21, 1);
        transform: translateY(-10px);
      }
    }
    
    @keyframes xAxis3 {
      50% {
        animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.94);
        transform: translateX(100px);
      }
    }

    #unicyclist {
      position: relative;
      z-index: 1000;
      height: 350px;
      transition: all .25s ease;

      @media only screen and (max-width : 740px) {
        height: 250px;
      }
    }

    #balancer {
      position: relative;
      z-index: 999;
      height: 250px;
      top: -43px;
      left: -41px;
      transition: all .25s ease;

      @media only screen and (max-width : 740px) {
        height: 150px;
        left: -31px;
      }
    }
  `}>
    <div className="spotlight" id="spotlight1" />
    <div className="spotlight" id="spotlight2" />
    <div className="spotlight" id="spotlight3" />
    <img src={Unicyclist} alt="" id="unicyclist" />
    <img src={Balancer} alt="" id="balancer" />
  </section>
);

export default HomeAnimation;
