import styled from '@emotion/styled';
import {Link} from 'gatsby';

const ActionLink = styled(Link)`
  display: inline-block;
  font-weight: 700;
  color: #51499B;
  background: #FDD35A;
  border-radius: 30px;
  padding: 10px 30px;
  text-decoration: none;
  font-size: .85rem;
  transition: all ease 0.25s;

  &:hover {
    box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
  }
`;

export default ActionLink;