import React from 'react';
import {css} from '@emotion/core';

import logo from '../assets/logo.svg';
import Facebook from '../assets/facebook.svg';
import LinkedIn from '../assets/linkedIn.svg';
import ActionLink from './action-link';

const GetInTouch = () => (
  <section className="fade-in" css={css`
    color: #1E49A2;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
    background: #E5DDBC;

    @media screen and (min-width: 1000px) {
      background-size: 105%;
    }

    h2 {
      color: #1E49A2;
    }

    p {
      margin-bottom: 1rem;
    }
  `}>
    <h2>Got a project you want to discuss?</h2>
    <p>We're currently available and accepting new projects, so why not get in touch and see how we can help your business.</p>
    <ActionLink css={css`background: #1E49A2; color: #E5DDBC;`} to="/contact/">Get In Touch</ActionLink>
  </section>
),
FooterHeadSvg = (props) => (
  <div className="fade-in" css={css`display: block; position: relative; overflow: hidden; width: 100%;`}>
    <div css={css`
      margin-left: -50vw;
      left: 50%;
      width: 100vw;
      height: 50px;
      position: relative;
      background: ${props.pageTitle === 'Contact Us' || !props.flip ? `#fff` : `#F3F4F6`};
      margin-top: ${props.pageTitle === 'Contact Us' ? `0` : `-1px`};

      @media only screen and (max-width : 800px) {
        height: 30px;
      }
      @media only screen and (max-width : 600px) {
        height: 20px;
      }

      svg {
        width: 100%;
        left: 0;
        bottom: -1px;
        height: 100%;
        position: absolute;
        fill: ${props.pageTitle === 'Contact Us' ? `#F3F4F6` : `#E5DDBC`};
        transform: ${props.pageTitle === 'Contact Us' || !props.flip ? `none` : `rotate(180deg);`};

        path:nth-of-type(1) {
          opacity: 0.15;
        }
        path:nth-of-type(2) {
          opacity: 0.3;
        }
      }
    `}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
        <path d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"></path>
        <path d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"></path>
        <path d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"></path>
      </svg>
    </div>
  </div>
),
Footer = (props) => (
  <>
    {props.pageTitle === "Contact Us" ? '' : <FooterHeadSvg pageTitle={props.pageTitle} />}
    {props.pageTitle === "Contact Us" ? '' : <GetInTouch />}
    <FooterHeadSvg pageTitle={props.pageTitle} flip="true" />
    <footer className="fade-in"
      css={css`
        background: #F3F4F6;
      `}
    >
      <section css={css`
        max-width: 1000px;
        padding: 0 .5rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-top: ${props.pageTitle === 'Contact Us' ? `50px !important` : `20px !important`};
        padding-bottom: 20px !important;

        @media only screen and (max-width : 600px) {
          flex-direction: column;
        }

        img {
          margin-bottom: 1rem;
        }

        span { 
          color: #6E6C6C;
          font-size: .75rem;
          line-height: 1.2;
          margin-bottom: 1rem;

          a {
            font-size: 1.2rem;
            font-weight: 700;
            text-decoration: none;
            color: #0094D6;
            display: block;
          }
        }
      `}>
        <img src={logo} alt="Talen logo" width="140" />
        {/* <span>
          Phone

          <a href="tel:(03) 5611 0428">(03) 5611 0428</a>
        </span> */}
        <span>Email
<a href="mailto:info@talen.com.au">info@talen.com.au</a></span>
      </section>

      <div css={css`background: #fff;`}>
        <section css={css`
          max-width: 1000px;
          padding: 0 .5rem;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px !important;

          p { 
            padding-top: 10px !important;
            color: #6E6C6C;
            font-size: .75rem;
          }

          div {
            padding-top: 5px !important;

            a { margin-left: 10px;}
          }

          .build {
            color: #fff;
          }
        `}>
          <p>&copy; Talen {new Date().getFullYear()}. All rights reserved. <span class="build">Build 2022Jan31.2</span></p>
          <div>
            <a href="http://facebook.com/talencomau" target="_blank" rel="noopener noreferrer">
              <img width="30" height="30" src={Facebook} alt="Facebook" />
            </a>
            <a href="http://www.linkedin.com/company/talen-pty-ltd" target="_blank" rel="noopener noreferrer">
              <img width="30" height="30" src={LinkedIn} alt="LinkedIn" />
            </a>
          </div>
        </section>
      </div>
    </footer>
  </>
);

export default Footer;
