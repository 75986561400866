import React from 'react';
import {Global, css} from '@emotion/core';
import Helmet from 'react-helmet';
import Header from './header';
import Footer from './footer';
import useSiteMetadata from '../hooks/use-sitemetadata';

const Layout = (props) => {
  const {title, description} = useSiteMetadata();

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          html,
          body,
          textarea {
            margin: 0;
            color: #555;
            font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';
            font-size: 18px;
            line-height: 1.4;

            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #222;
            line-height: 1.1;
            font-weight: 900;

            + * {
              margin-top: 0.5rem;
            }
          }

          h2 {
            font-size: 24pt;
          }

          h3, h4, h5 { color: #0787CE; }

          strong {
            color: #222;
          }

          li {
            margin-top: 0.25rem;
          }

          p {
            margin-bottom: 0.75rem;
          }

          .gatsby-resp-image-image {
            width: 100%;
            height: 100%;
            margin: 0;
            vertical-align: middle;
            position: absolute;
            top: 0;
            left: 0;
          }

          .fade-in {
            animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          }

          /* ----------------------------------------------
          * Generated by Animista on 2019-8-2 9:19:20
          * w: http://animista.net, t: @cssanimista
          * ---------------------------------------------- */

          /**
           * ----------------------------------------
           * animation fade-in
           * ----------------------------------------
           */
          @keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.5.1/dist/leaflet.css" />
      </Helmet>
      <Header pageTitle={props.page} />
      <main className="fade-in"
        css={css`
          margin: ${props.page === 'Home' ? `2rem 0` : `2rem auto`};
          max-width: ${props.page === 'Home' ? `100%` : `1000px`};
          padding: ${props.page === 'Home' ? `0` : `0 .5rem`};

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
            display: block;
          }
        `}
      >
        {props.children}
      </main>
      <Footer pageTitle={props.page} />
    </>
  );
};

export default Layout;
